<template>
  <div>
    <mt :backType="2">
      <el-form v-model="form" :inline="true" :model="form" align="left" label-width="160px">
        <el-form-item label="客户姓名">
          <el-input v-model="form.name" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-if="form.gender==1" readonly style="width: 160px" value="男"></el-input>
          <el-input v-else-if="form.gender==0" readonly style="width: 160px" value="女"></el-input>
          <el-input v-else readonly style="width: 160px" value=" "></el-input>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="form.age" readonly style="width: 210px"></el-input>
        </el-form-item>
        <el-form-item label="卡号" label-width="120px">
          <el-input v-model="form.card" readonly style="width: 160px"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="联系电话" style="">
          <el-input v-model="form.phone" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="电话2">
          <el-input v-model="form.phone2" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="所属员工">
          <el-input v-if="form.sellerName" :value="form.sellerName+'('+form.sellerMobile+')'" readonly
                    style="width: 210px"></el-input>
          <el-input v-else readonly style="width: 210px" value=""></el-input>
        </el-form-item>
        <el-form-item label="积分" label-width="120px">
          <el-input v-model="form.score||0" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="备注" label-width="80px">
          <el-input v-model="form.remark" readonly style="width: 300px"></el-input>
        </el-form-item>
        <br>
        <el-form-item label="默认收货人">
          <el-input v-model="form.receiver" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="默认收货人电话">
          <el-input v-model="form.receiverPhone" readonly style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="默认收货地址">
          <el-input v-model="form.receiverAddress" readonly style="width: 450px"></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button plain @click="createOrder">下单</el-button>
          <el-button plain @click="deleteCustomer">删除</el-button>
        </el-form-item>
        <br>
      </el-form>
    </mt>
    <el-card shadow="always" style="margin:30px">
      <div slot="header" class="clearfix">
        <!--                <el-button  style="float: left" @click="newOrder">下单</el-button>-->
        <!--                <el-button  style="float: left;">导出</el-button>-->
        <span class="title">已购订单</span>
      </div>
      <el-table :data="orders" border class="funBtn" stripe>
        <el-table-column label="NO." type="index" width="70px"></el-table-column>
        <el-table-column label="订单编号" prop="id"></el-table-column>
        <el-table-column label="下单日期" min-width="120px" prop="createTime"></el-table-column>
        <el-table-column label="客户" min-width="120px" prop="customerId">
          <template slot-scope="scope">
            {{ scope.row.customerName }}
            <template v-if="scope.row.customerPhone">({{ scope.row.customerPhone }})</template>
          </template>
        </el-table-column>
        <el-table-column label="下单客服" prop="creatorId">
          <template slot-scope="scope">
            {{ scope.row.userName }}
            <template v-if="scope.row.userMobile">({{ scope.row.userMobile }})</template>
          </template>
        </el-table-column>
        <el-table-column label="订单金额" prop="money">
          <template slot-scope="scope">
            {{ scope.row.money | fen2yuan }}
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="state">
          <template slot-scope="scope">
            <span v-if="scope.row.state==0">待审核</span>
            <span v-if="scope.row.state==1">已审核</span>
          </template>
        </el-table-column>
        <el-table-column label="商品" prop="goods">
          <template slot-scope="scope">
            <pre><span v-for="s in scope.row.details">{{ s.goodsName || '' }}[{{ s.count }}]&#9;</span></pre>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="340">
          <template slot-scope="scope">
            <el-button @click="orderDetail(scope.row)">详情</el-button>
            <!--                        <el-button >打小票</el-button>-->
            <el-button v-if="isAdmin&&(scope.row.state==0)" @click="edit(scope.row)">编辑</el-button>
            <el-button v-if="isAdmin&&(scope.row.state==0)" @click="review(scope.row)">审核</el-button>
            <el-button v-if="scope.row.state==0" type="danger" @click="orderDelete(scope.row)">删除</el-button>
            <el-button v-if="isAdmin&&(scope.row.state==1)" type="danger" @click="orderInvalid(scope.row)">作废</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager">
        <el-pagination
            :current-page.sync="params.page"
            :page-size.sync="params.size"
            :page-sizes="[50, 100]"
            :total="params.total"
            layout="sizes, prev, pager, next,total"
            @size-change="onQuery"
            @current-change="onQuery">
        </el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
import mt from "@/components/MainTop.vue"

export default {
  components: {mt},
  name: 'CustomerDetail',
  props: ['customerId', 'mobile'],
  data() {
    return {
      customer: {},
      form: {},
      orders: [],
      params: {
        showInvalid:false,
        customerId: "",
        mobile: "",
        page: 1,
        size: 50,
        total: 0
      }
    }
  },
  mounted() {
    localStorage.removeItem("cartGoods");
    let _this = this;
    this.back = this.showBack || false;
    this.params.customerId = this.customerId;
    this.params.mobile = this.mobile;
    this.$http.get("/api/customer/find", {
      params: {
        customerId: this.customerId,
        mobile: this.mobile
        , page: 1, size: 1
      }
    })
        .then(rsp => {
          if (rsp.data.status == 200) {
            if (rsp.data.data.length) {
              _this.customer = rsp.data.data[0];
              _this.params.customerId = _this.customer.id
              _this.form = _this.customer;
              _this.onQuery();
            }
          }
        })
  },
  methods: {
    newOrder() {
      this.$router.push("/main/customer/newOrder");
    },
    onQuery() {
      let _this = this;
      this.$http.get("/api/order/find", {params: this.params})
          .then(rsp => {
            if (rsp.data.status == 200) {
              _this.orders = rsp.data.data || [];
              this.params.total = rsp.data.count;
            }
          })
    },
    orderDetail(row) {
      this.$router.push({name: "OrderDetail", params: {orderId: row.id}})
    },
    edit(row) {
      this.$router.push({name: "OrderEdit", params: {orderId: row.id}})
    },
    orderDelete(row) {
      this.$http.post("/api/order/delete/" + row.id)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.onQuery();
            }
          });
    },
    orderInvalid(row){
      this.$http.delete("/api/order/invalid/" + row.id)
          .then(rsp => {
            if (rsp.data.status == 200) {
              this.$message({
                type: 'success',
                message: '作废成功!'
              });
              this.onQuery();
            }
          });
    },
    review(row) {
      let _this = this;
      this.$confirm('审核通过后，将不可恢复，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/order/review/" + row.id)
            .then(rsp => {
              if (rsp.data.status == 200) {
                this.$message({
                  type: 'success',
                  message: '审核成功!'
                });
                this.onQuery();
                _this.$forceUpdate();
              }
            })

      }).catch(() => {

      });
    },
    createOrder() {
      let _form = this.form;
      localStorage.removeItem("cartGoods");
      this.$router.push({name: "CustomerNewOrder", params: {form: _form}});
    },
    deleteCustomer() {
      this.$confirm('确认要删除当前客户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete("/api/customer/delete/" + this.form.id)
            .then((rsp) => {
              if (rsp.data.status == 200) {
                this.$message.success("删除成功");
                this.$router.push({name: "Customer"});
              }else{
                this.$message.error(rsp.data.message);
              }
            }).catch((rsp) => {
          this.$message.error(rsp.response.data.message);
        })
      }).catch(() => {
      });
    }
  }
}
</script>

<style scoped>
.el-form-item {
  margin: 18px auto
}

.el-form {
  padding: 18px 0;
}
</style>
